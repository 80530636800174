import Input from "@/components/Input";
import Spinner from "@/components/Spinner";
import { useUpdateTemplateCollection } from "@/hooks/template";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { CloseIcon } from "@/components/Icons";

function TemplateSettingsSection({ data }: { data: any }) {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    unregister,
    getValues
  } = useForm();
  const [emails, setEmails] = useState(
    (data ?? { settings: {email: undefined} }).settings?.email != undefined ?
       (data?.settings?.email as string).split(",").map(email => email.trim())
      : [""]
  );

  useEffect(() => {
    setEmails(
      (data ?? { settings: undefined }).settings != undefined
      ? (data.settings.email as string).split(",").map(email => email.trim())
      : [""]
    )
  }, [data]);

  useEffect(() => {
    const registeredEmails = Object.keys(getValues()).filter(key => key.startsWith('email')).length;
    for(let i = emails.length; i < registeredEmails; i++) {
      unregister(`email-${i}`);
    }
    if (data) {
      if((data ?? { settings: {email: undefined} }).settings?.email != undefined) {
        setValue("enabled", data.settings.enabled);
        emails.forEach((email, index) => {
          console.log('setValue ', `email-${index}`);
          setValue(`email-${index}`, email);
        })
      } else {
        setValue("email-0", "");
        setValue("enabled", false);
      }
    }
  }, [emails])

  const addEmailField = () => {
    setEmails([...emails, ""]);
  };

  const removeEmailField = (index: number) => {
    if (emails.length > 1) {
      const updatedEmails = emails.filter((_, i) => i !== index);
      setEmails(updatedEmails);
      unregister(`email-${index}`);
    }
  };

  const { updateTemplateCollection, updateTemplateCollectionLoading } =
    useUpdateTemplateCollection();

  if (data == undefined) {
    return (
      <div className="w-full h-full items-center justify-center">
        <span className="loading loading-bars loading-xs"></span>
      </div>
    );
  }

  const onSubmit = async (formData: any) => {
    Object.entries(formData).forEach(([key, value]) => {
      console.log(`${key}: ${value}`);
    });

    const emailString = Object.entries(formData)
      .filter(([key, value]) => key.startsWith("email") && (value as string).trim() !== "")
      .map(([_, value]) => value)
      .join(",");
    
    const mydata = {
      email: emailString,
      enabled: formData.enabled,
    };

    await updateTemplateCollection({ id: [data.id], settings: mydata });
  };

  return (
    <div className="pt-20 px-4 flex flex-col h-full">
      <h1 className="text-2xl pb-2">Template Settings</h1>
      <p className="font-light text-sm pb-6">
        Please review the settings for your template ‘{data.name}’.
      </p>
      <form
        className="flex flex-col gap-y-4 h-full"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Input
          type="email"
          name={`email-0`} // Unique name per input
          label={"Email"}
          errors={errors}
          register={register}
          validationSchema={{
            required: "Email is required",
            pattern: {
              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              message: "Invalid email format",
            },
          }}
          required
          value={emails[0]}
        />

        {emails.map((email, index) => (
          <div key={index} className="flex items-end gap-2">
            {index > 0 && (
              <>
                <Input
                  type="email"
                  name={`email-${index}`} // Unique name per input
                  label={`Email ${index + 1}`}
                  errors={errors}
                  register={register}
                  validationSchema={{
                    required: "Email is required",
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: "Invalid email format",
                    },
                  }}
                  required
                />
                <button
                  type="button"
                  className={`p-2 rounded-md bg-red-500 hover:bg-red-600 text-white ${errors[`email-${index}`] ? " mb-8" : " mb-2"}`}
                  onClick={() => removeEmailField(index)}
                >
                  <CloseIcon height={16} width={16} />
                </button>
              </>
            )}
          </div>
        ))}
        <button
          type="button"
          className="btn btn-info capitalize font-normal mt-auto"
          onClick={addEmailField}
        >
          + Add Another Email
        </button>
        <div className="form-control">
          <label className="label cursor-pointer">
            <span className="label-text">Enable Rule</span>
            <input
              type="checkbox"
              id="enabled"
              {...register("enabled")}
              className={`form-check-input ${
                errors.enabled ? "is-invalid" : ""
              }`}
            />
          </label>
        </div>
        <button
          className="btn btn-info capitalize font-normal mt-auto"
          type="submit"
        >
          <p className="pr-2">Save</p>
          {updateTemplateCollectionLoading && <Spinner />}
        </button>
      </form>
    </div>
  );
}

export default TemplateSettingsSection;
