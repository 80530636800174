import { pb } from "@/lib/pocketbase";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export const USER_QUERY_KEY = 'user';

export const getUserCollection = async () => {
  try {
    const users = await pb.collection('user').getFullList();
    return users
  } catch (err) {
    return undefined;
  }
};

export const deleteUser = async (id:string) => {
  try {
    const users = await pb.collection('user').delete(id);
    return users
  } catch (err) {
    return undefined;
  }
}
export const getUserLicenses = async () => {
  //TODO: refactor for backend to remove .getFullList
  try {
    const users = await pb.collection('user').getFullList();
    const subscriptions = await pb.collection('subscription').getFullList();

    const userSubscriptions = users.map(item1 => {
      let item2 = subscriptions.find(item2 => item1.id === item2.user_id);
      return {...item1, ...item2};
     });

     const prices = await pb.collection('price').getFullList();
    const products = await pb.collection('product').getFullList();

    const productPrices = prices.map(item1 => {
      let item2 = products.find(item2 => item1.product_id === item2.product_id);
      return {...item1, ...item2};
     });

    const userSubscriptionProductPrices = userSubscriptions.map(item1 => {
      let item2 = productPrices.find(item2 => item1.price_id === item2.price_id);
      return {...item1, ...item2};
     });



    const total = userSubscriptions.reduce((accumulator, item) => accumulator + (item.quantity ?? 0), 0);
    const consumed = userSubscriptions.reduce((accumulator, item) => accumulator + (item.assigned_subscriptionId ? 1 : 0), 0);

  console.log(userSubscriptionProductPrices)
  console.log(productPrices)

    return {
      total: total,
      consumed: consumed,
      users: userSubscriptionProductPrices
    }
  } catch (err) {
    return undefined;
  }
}


export function useGetUserLicensesCollection() {
  const { isLoading, isError, data, error } = useQuery(
    ['License'],
    () => getUserLicenses(),
    {
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
      staleTime: 1_000, // 3s
    },
  );

  return {
    data: data,
    isError,
    isLoading,
    error: error as Error,
  };
}


export const updateUser = async (args:any) => {  
  await pb.collection('user').update(args.id, args.user);
}

export const sendResetPasswordEmail = async (email: string) => {  
  return await pb.collection('user').requestPasswordReset(email);
}

export function useUpdateUserCollection() {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    async (args: any) =>
      await updateUser(args),
    {
      onSuccess: async () => {
        // Invalidate and refetch
        await queryClient.invalidateQueries([USER_QUERY_KEY]);
      },
    },
  );
  return {
    updateUserCollection: async (args: any) => {
      try {
        await mutation.mutateAsync(args);
        return true;
      } catch (err) {
        return false;
      }
    },
    updateUserCollectionError: mutation.isError,
    updateUserCollectionLoading: mutation.isLoading,
  };
}

export function useDeleteUser() {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    async (args: any) =>
      await deleteUser(args),
    {
      onSuccess: async () => {
        // Invalidate and refetch
        await queryClient.invalidateQueries([USER_QUERY_KEY]);
      },
    },
  );
  return {
    deleteUser: async (args: any) => {
      try {
        await mutation.mutateAsync(args);
        return true;
      } catch (err) {
        return false;
      }
    },
    deleteUserError: mutation.isError,
    deleteUserLoading: mutation.isLoading,
  };
}

export function useUserCollection() {
  const { isLoading, isError, data, error } = useQuery(
    [USER_QUERY_KEY],
    () => getUserCollection(),
    {
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
      // staleTime: 30_000, // 3s
    },
  );

  return {
    users: data,
    isError,
    isLoading,
    error: error as Error,
  };
}

export function useCreateUserCollection() {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    async (args: any) =>
      await pb.collection('user').create(args),
    {
      onSuccess: async (data) => {
        console.log(data);
        
        await pb.collection('user').requestVerification(data.email);
        // Invalidate and refetch
        await queryClient.invalidateQueries([USER_QUERY_KEY]);
      },
    },
  );
  return {
    createUserCollection: async (args: any) => {
      try {
        await mutation.mutateAsync(args);
        return true;
      } catch (err) {
        return false;
      }
    },
    createUserCollectionError: mutation.isError,
    createUserCollectionLoading: mutation.isLoading,
  };
}