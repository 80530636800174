/* eslint-disable jsx-a11y/anchor-is-valid */
import Input from "@/components/Input";
import { useForm } from "react-hook-form";
import { userEditSignal } from "@/app/state";
import { useEffect, useState } from "react";
import useWindowDimensions from "@/hooks/windowDimentions";
import {
  useCreateUserCollection,
  useGetUserLicensesCollection,
  useUpdateUserCollection,
  sendResetPasswordEmail
} from "@/hooks/user";
import moment from "moment";
import Spinner from "@/components/Spinner";
import { Record } from "pocketbase";
var generator = require("generate-password-browser");

function UserAddEditSection({ user }: { user?: Record }) {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    unregister,
    watch,
  } = useForm();
  const { width } = useWindowDimensions();
  const { createUserCollection, createUserCollectionLoading } =
    useCreateUserCollection();
  const { updateUserCollection, updateUserCollectionLoading } =
    useUpdateUserCollection();
  const { data: userLicenses } = useGetUserLicensesCollection();

  const [selection, setSelection] = useState("User");
  const [sentResetEmail, setSentResetEmail] = useState(false);
  const [subscriptionSelection, setSubscriptionSelection] =
    useState("Unassigned");
  const [password, setPassword] = useState("");
  const [userSuccess, setUserSuccess] = useState(false);
  const background = subscriptionSelection === 'Business Basic' ? 'bg-primary' :
  subscriptionSelection === 'Business Premium' ? 'bg-gradient-to-r to-secondary from-primary' :
  subscriptionSelection === 'Business Ultimate' ? 'bg-gradient-to-r to-secondary via-primary from-info ' :
  'bg-slate-400';

  const onSubmit = async (formData: any) => {
    if (userEditSignal.value == "add") {
      const pass = generator.generate({
        length: 8,
        numbers: true,
      });
      setPassword(pass);
      const data = {
        ...formData,
        password: pass,
        passwordConfirm: pass,
        lastSeen: moment().toISOString(),
        emailVisibility: true,
      };
      const record = await createUserCollection(data);
      setUserSuccess(record);
    }
    if (userEditSignal.value === "edit" || userEditSignal.value === 'editPassword') {
      if (user != undefined) {
        const result = await updateUserCollection({ id: user.id, user: formData });
        setUserSuccess(result);
      }
    }
  };

  useEffect(() => {
    if(userEditSignal.value === 'add' || userEditSignal.value === 'edit') {
      unregister("oldPassword");
      unregister("password");
      unregister("passwordConfirm");
      if (!!user) {
        setValue("displayName", user.displayName);
        setValue("username", user.username);
        setValue("role", user.role);
        setValue("email", user.email);
        setValue("firstName", user.firstName);
        setValue("lastName", user.lastName);
        setSelection(user.role);
      }
    }
    if(userEditSignal.value === 'editPassword') {
      unregister("displayName");
      unregister("username");
      unregister("role");
      unregister("email");
      unregister("firstName");
      unregister("lastName");
    }
  }, [userEditSignal.value, user])

  useEffect(() => {
    setValue("role", selection);
  }, [selection]);

  const resetPassword = async () => {
    if(!user?.email) {
      console.log("user email not found");
      return;
    }
    const result = await sendResetPasswordEmail(user.email);
    if(result){
      setUserSuccess(true);
      setSentResetEmail(true);
    }
  }

  const SuccessSection = () => {
    return (
      <>      
        {(userEditSignal.value === 'add' && (
          <>
            <h1 className="text-3xl pb-4 capitalize">Successfully Created User</h1>
            <p className="font-light text-sm pb-4">
              Congratulations a user was successfully created. Below is the user's
              password. Please reset the following password at your earliest
              convenience for security
            </p>
            <div className="bg-slate-200 p-4 rounded-lg">
              <p className="font-light text-sm">{password}</p>
            </div>
          </>
        ))}
        {((userEditSignal.value !== 'edit' && !sentResetEmail) && (
          <>
            <h1 className="text-3xl pb-4 capitalize">Successfully Edited User</h1>
            <p className="font-light text-sm pb-4">
              Congratulations the user was successfully edited.
            </p>
          </>
        ))}
        {((userEditSignal.value !== 'edit' && sentResetEmail) && (
          <>
            <h1 className="text-3xl pb-4 capitalize">Successfully Sent Reset Password Email</h1>
            <p className="font-light text-sm pb-4">
              Congratulations the user Reset Password Email was successfully sent.
              Please look for the email on '{user?.email}' and check the spam.
            </p>
          </>
        ))}
      </>
    );
  };

  // if (data == undefined) {
  // 	return <div>loading...</div>
  // }

  return (
    <>
      {!createUserCollectionLoading || !updateUserCollectionLoading ? (
        <div className="pt-20 px-4 flex flex-col h-full">
          {!userSuccess ? (
            <>
              <div className="flex flex-row justify-between pb-4 items-end">
                <h1 className="text-3xl capitalize text-base-content">
                  {userEditSignal.value} User
                </h1>
                <h1 className="capitalize text-neutral">
                  Assigned {userLicenses?.consumed ?? 0} /{" "}
                  {userLicenses?.total ?? 0}
                </h1>
              </div>
              <p className="font-light text-sm pb-6 text-base-content">
                Please enter the required details for the user.
              </p>
              {(userEditSignal.value === 'add' || userEditSignal.value === 'edit') && (
                <form
                className="flex flex-col gap-y-4 pb-6"
                onSubmit={handleSubmit(onSubmit)}
                >
                  <Input
                    type="text"
                    name="displayName"
                    label="Display Name"
                    errors={errors}
                    register={register}
                    validationSchema={{
                      required: "Display Name is required",
                      minLength: {
                        value: 3,
                        message: "Please enter a minimum of 3 characters",
                      },
                    }}
                    required
                  />
                  <Input
                    type="text"
                    name="username"
                    label="Username"
                    errors={errors}
                    register={register}
                    validationSchema={{
                      required: "Username is required",
                      minLength: {
                        value: 3,
                        message: "Please enter a minimum of 3 characters",
                      },
                    }}
                    required
                  />
                  <div className="hidden">
                    <Input
                      //Classname
                      className="hidden"
                      type="text"
                      name="role"
                      label="Role"
                      errors={errors}
                      register={register}
                      validationSchema={{
                        required: "Role is required",
                        minLength: {
                          value: 3,
                          message: "Please enter a minimum of 3 characters",
                        },
                      }}
                      required
                    />
                  </div>

                  <div
                    className={`dropdown ${
                      width < 640 ? "dropdown-top" : ""
                    } w-full pb-1`}
                  >
                    <label className="text-sm text-base-content">Role</label>
                    <label
                      tabIndex={0}
                      className="btn w-full bg-slate-100 h-4 border-slate-500 text-base-content capitalize"
                    >
                      {selection}
                    </label>
                    <ul
                      tabIndex={0}
                      className="dropdown-content menu p-2 shadow bg-slate-400 rounded-box w-full text-base-content flex flex-col items-center"
                    >
                      <li className="w-full" onClick={() => setSelection("User")}>
                        <a>User</a>
                      </li>
                      <li
                        className="w-full"
                        onClick={() => setSelection("Service")}
                      >
                        <a>Service</a>
                      </li>
                      <li
                        className="w-full"
                        onClick={() => setSelection("Admin")}
                      >
                        <a>Admin</a>
                      </li>
                    </ul>
                  </div>
                  <div
                    className={`dropdown ${
                      width < 640 ? "dropdown-top" : ""
                    } w-full pb-1`}
                  >
                    <label className="text-sm text-base-content">
                      Subscription
                    </label>
                    <label
                      tabIndex={0}
                      className={"btn w-full h-4 border-slate-500 text-base-content capitalize " + background}
                    >
                      {subscriptionSelection}
                    </label>
                    <ul
                      tabIndex={0}
                      className="dropdown-content menu p-2 shadow bg-slate-400 rounded-box w-full text-base-content flex flex-col items-center"
                    >
                      <li
                        className="w-full"
                        onClick={() => setSubscriptionSelection("Business Basic")}
                      >
                        <a>Business Basic</a>
                      </li>
                      <li
                        className="w-full"
                        onClick={() =>
                          setSubscriptionSelection("Business Premium")
                        }
                      >
                        <a>Business Premium</a>
                      </li>
                      <li
                        className="w-full"
                        onClick={() =>
                          setSubscriptionSelection("Business Ultimate")
                        }
                      >
                        <a>Business Ultimate</a>
                      </li>
                    </ul>
                  </div>
                  <Input
                    type="email"
                    name="email"
                    label="Email Address"
                    errors={errors}
                    register={register}
                    validationSchema={userEditSignal.value === 'add' ? {
                      required: "Email is required",
                      minLength: {
                        value: 3,
                        message: "Please enter a minimum of 3 characters",
                      },
                    } : {}}
                    required={userEditSignal.value === 'add' ? true : false}
                  />
                  <Input
                    type="text"
                    name="firstName"
                    label="First Name"
                    errors={errors}
                    register={register}
                    validationSchema={{
                      minLength: {
                        value: 3,
                        message: "Please enter a minimum of 3 characters",
                      },
                    }}
                  />
                  <Input
                    type="text"
                    name="lastName"
                    label="Last Name"
                    errors={errors}
                    register={register}
                    validationSchema={{
                      minLength: {
                        value: 3,
                        message: "Please enter a minimum of 3 characters",
                      },
                    }}
                  />
                  <button
                    className="btn btn-info capitalize font-normal mt-auto"
                    type="submit"
                  >
                    Save
                  </button>
                </form>
              )}
              {userEditSignal.value === 'editPassword' && (
                <>
                  <form
                  className="flex flex-col gap-y-4 pb-6"
                  onSubmit={handleSubmit(onSubmit)}
                  >
                    {/* <Input
                      type="text"
                      name="oldPassword"
                      label="Old Password"
                      errors={errors}
                      register={register}
                      validationSchema={{
                        required: "Old Password is required",
                        minLength: {
                          value: 3,
                          message: "Please enter a minimum of 3 characters",
                        },
                      }}
                      required
                    /> */}

                    <Input
                      type="text"
                      name="password"
                      label="password"
                      errors={errors}
                      register={register}
                      validationSchema={{
                        required: "Password is required",
                        minLength: {
                          value: 3,
                          message: "Please enter a minimum of 3 characters",
                        },
                      }}
                      required
                    />

                    <Input
                      type="text"
                      name="passwordConfirm"
                      label="Confirm Password"
                      errors={errors}
                      register={register}
                      validationSchema={{
                        required: "Confirm Password is required",
                        validate: (value:any) =>
                          value === watch("password") || "Passwords must match",
                      }}
                      required
                    />

                    <button
                      className="btn btn-info capitalize font-normal mt-auto"
                      type="submit"
                    >
                      Save
                    </button>
                  </form>
                  {!!user?.email ? (
                    <button
                      className="btn btn-info capitalize font-normal mt-auto"
                      onClick={resetPassword}
                    >
                      Send Reset Password Email
                    </button>
                  ) : (
                    <p className="font-light text-sm pb-6 text-base-content text-center">
                      User email is not visible for Send Password Reset Email
                    </p>
                  )}
                </>
              )}
            </>
          ) : (
            <SuccessSection />
          )}
        </div>
      ) : (
        <div className="h-full w-full flex flex-col items-center justify-center">
          <div className="scale-[3] pb-4">
            <Spinner />
          </div>
          <div className="pr-3">Loading...</div>
        </div>
      )}
    </>
  );
}

export default UserAddEditSection;
