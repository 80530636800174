import { IUser } from "@/shared/models/base.models";
import { signal, computed, effect } from "@preact/signals-react";

export const userSignal = signal<IUser | undefined>(undefined)
export const userEditSignal = signal<'add' | 'edit' | 'editPassword' | undefined>(undefined)
export const dashboardWelcomeSignal = signal<boolean>(true)
export const setUser = (value: any) => {userSignal.value = value};
export const user = computed(() => {
	let item: IUser | undefined = undefined
	try {
		item = JSON.parse(sessionStorage.getItem('user') ?? '');
	} catch (error) {
		item = undefined
	}
	if ((userSignal.value == item) || (item == undefined)) {
		return userSignal.value;
	}
	return item;
});
effect(() => {
	if (userSignal.value != undefined) {
		sessionStorage.setItem('user', JSON.stringify(userSignal.value));
	}
});