import { useEffect, useState } from "react";
import NavLayout from "@/components/NavLayout";
import Button from "@/components/Button";
import Table from "@/components/Table";
import { useDeleteUser, useUserCollection } from "@/hooks/user";
import UserAddEditSection from "@/sections/user/UserAddEditSection";
import { Link, useNavigate, useParams } from "react-router-dom";
import { TemplatesIcon, TimeIcon } from "@/components/Icons";
import { userEditSignal } from "@/app/state";
import moment from "moment";
import { useSubmissionCollection } from "@/hooks/submission";
import Spinner from "@/components/Spinner";

function UserManagePage(props: any) {
  const { users, isLoading } = useUserCollection();
  const { submissions, isLoading: isSubmissionsLoading } =
    useSubmissionCollection();
  const { deleteUser } = useDeleteUser();
  const navigate = useNavigate();
  const { id } = useParams();

  const [selected, setSelected] = useState<string[]>();

  useEffect(() => {
    userEditSignal.value = "edit";
  }, []);

  const currentUser = users?.filter((x) => x.id == id)[0];
  const structureSubmissions = submissions
    ?.filter((x) => x.userName == id)
    .map((submission: any) => {
      return {
        id: submission.id,
        name: submission.name,
        submissionDateTime: submission.submissionDateTime,
        status: submission.consumeCount > 0,
        redoneCount: submission.redoneCount,
        size: submission.size,
      };
    });

  const handleDelete = (id: string) => {
    deleteUser(id);
    navigate("/users");
  };

  if (isLoading || isSubmissionsLoading) {
    return (
      <NavLayout>
        <div className="w-full h-full min-h-[500px] flex justify-center items-center">
          <Spinner/>
        </div>
      </NavLayout>
    );;
  }
  if (!currentUser) {
    return <>404</>;
  }

  return (
    <div>
      <NavLayout overlayChildren={<UserAddEditSection user={currentUser} />}>
        <div className="flex flex-col p-8 gap-y-4">
          <h1 className="text-2xl font-roboto">
            Manage {(currentUser ?? { displayName: "" }).displayName}
          </h1>
          <div className="flex flex-row justify-between">
            <label
              htmlFor="my-drawer-4"
              className="drawer-button"
              onClick={() => {
                userEditSignal.value = "edit";
              }}
            >
              <Button
                className="w-28 text-2xs md:text-xs md:w-40 pointer-events-none"
                appearance="accent"
              >
                Edit User
              </Button>
            </label>
            <label
              htmlFor="my-drawer-4"
              className="drawer-button"
              onClick={() => {
                userEditSignal.value = "editPassword";
              }}
            >
              <Button
                className="w-28 text-2xs md:text-xs md:w-40 pointer-events-none"
                appearance="accent"
              >
                Edit Password
              </Button>
            </label>
            <label
              onClick={() => {
                currentUser != undefined && handleDelete(currentUser.id);
              }}
              htmlFor="modal-delete-user"
              className=""
            >
              <Button
                className="w-28 text-2xs md:text-xs md:w-40 pointer-events-none"
                appearance="destructive"
              >
                Delete User
              </Button>
            </label>
          </div>
          <div className="bg-slate-100 w-full h-28 rounded-lg flex flex-col items-center justify-center">
            <div className="flex flex-row items-center justify-between w-44 mr-4">
              <div className="tooltip" data-tip="No. Submissions">
                <div className="flex flex-row items-center">
                  <TemplatesIcon className="scale-[1.5]" />
                  <p className="pl-2 text-3xl">
                    {(structureSubmissions ?? []).length}
                  </p>
                </div>
              </div>

              <div className="tooltip" data-tip="Last Seen">
                <div className="flex flex-row items-center">
                  <TimeIcon className="scale-[1.1]" />
                  <p className="pl-3 text-xs w-12">
                    {moment(
                      Date.parse((currentUser ?? { lastSeen: "-" }).lastSeen)
                    ).format("DD/MM/YYYY h:mma")}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="pt-4">
            <h2 className="text-lg pb-2">Submissions</h2>
            <div className="flex flex-row justify-between">
              <Link to={"/reports"}>
                <Button
                  className="w-28 text-2xs md:text-xs md:w-40 pointer-events-none"
                  appearance="accent"
                >
                  View Reports
                </Button>
              </Link>
              {(selected ?? []).length > 0 && (
                <label htmlFor="modal-delete-submissions" className="">
                  <Button
                    className="w-28 text-2xs md:text-xs md:w-40 pointer-events-none"
                    appearance="destructive"
                  >
                    Delete Submissions
                  </Button>
                </label>
              )}
            </div>
          </div>
          {structureSubmissions?.length == 0 ? (
            <div className="text-center pt-10">
              <i>Start using Sign365 to see your submissions.</i>
            </div>
          ) : (
            <Table
              dataSource={structureSubmissions ?? []}
              mobileVisibleKeys={["name", "submissionDateTime", "status"]}
              hidePrimaryKey={true}
              primaryKey="id"
              hideOptions
              hideCheckbox={false}
              setCheckboxSelected={setSelected}
              checkboxSelected={selected}
              onItemClick={(id: string) => {
                try {
                  const filename = submissions?.filter(
                    (submission) => submission.id == id
                  )[0].file;
                  window
                    .open(
                      `http://127.0.0.1:8090/api/files/submission/${id}/${filename}`,
                      "_blank",
                      "noreferrer"
                    )
                    ?.focus();
                } catch (error) {
                  return;
                }
              }}
            />
          )}
        </div>
      </NavLayout>
      <input type="checkbox" id="modal-delete-user" className="modal-toggle" />
      <label
        htmlFor="modal-delete-user"
        className="modal cursor-pointer modal-bottom sm:modal-middle"
      >
        <label className="modal-box relative" htmlFor="">
          <h3 className="text-lg font-bold text-base-content">
            Are you sure you want to delete this user?
          </h3>
          <p className="py-4">
            The action you are about to perform is irreversible and the user
            will be permenatly deleted.
          </p>
          <div className="flex flex-row justify-between">
            <div className="modal-action">
              <label htmlFor="modal-delete-user" className="">
                <Button
                  className="w-full pointer-events-none text-2xs"
                  appearance="destructive"
                >
                  Delete
                </Button>
              </label>
            </div>
            <div className="modal-action w-full">
              <label htmlFor="modal-delete-user" className="">
                <Button
                  className="w-full pointer-events-none text-2xs"
                  appearance="secondary"
                >
                  Cancel
                </Button>
              </label>
            </div>
          </div>
        </label>
      </label>
      <input
        type="checkbox"
        id="modal-delete-submissions"
        className="modal-toggle"
      />
      <label
        htmlFor="modal-delete-submissions"
        className="modal cursor-pointer modal-bottom sm:modal-middle"
      >
        <label className="modal-box relative" htmlFor="">
          <h3 className="text-lg font-bold text-base-content">
            Are you sure you want to delete these submissions?
          </h3>
          <p className="py-4">
            The action you are about to perform is irreversible and these
            templates will be permenatly deleted.
          </p>
          <div className="flex flex-row justify-between">
            <div className="modal-action">
              <label htmlFor="modal-delete-submissions" className="">
                <Button
                  className="w-full pointer-events-none text-2xs"
                  appearance="destructive"
                >
                  Delete
                </Button>
              </label>
            </div>
            <div className="modal-action w-full">
              <label htmlFor="modal-delete-submissions" className="">
                <Button
                  className="w-full pointer-events-none text-2xs"
                  appearance="secondary"
                >
                  Cancel
                </Button>
              </label>
            </div>
          </div>
        </label>
      </label>
    </div>
  );
}

export default UserManagePage;
